// Javascript goes here
import "bootstrap";
import IMask from "imask";
import "./header.js";
import "./swiper.js";

// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import the Facebook and Twitter icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// add the imported icons to the library
library.add(fas, fab, far);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

document.addEventListener("DOMContentLoaded", function () {
  IMask(document.getElementById("phoneInput"), {
    mask: "(000)000-0000",
  });
});
