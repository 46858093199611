import Swiper from "swiper/bundle";
import { Pagination, A11y, Navigation } from "swiper/modules";
// import "swiper/css";
const swiper = new Swiper(".swiper", {
  modules: [Pagination, A11y, Navigation],
  // Optional parameters
  loop: true,
  autoplay: {
    delay: 3000,
  },
  slidesPerView: 1,
  breakpoints: {
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 480px
    764: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    // when window width is >= 640px
  },
  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    type: "bullets",
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-btn-next",
    prevEl: ".swiper-btn-prev",
  },
});
